import React, { ReactElement } from 'react'
import { Image, Wrapper } from '@farewill/ui'

const Brochure = ({
  guideType,
}: {
  guideType: 'email' | 'post'
}): ReactElement => (
  <Wrapper>
    <Image
      path={
        guideType === 'email'
          ? 'com-assets/guide-brochure/guide_on_ipad'
          : 'com-assets/guide-brochure/guide_mock_up_printed'
      }
      ext="jpg"
      width={300}
      widthFromL={500}
    />
  </Wrapper>
)

export default Brochure
