import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, H, Image, P, Wrapper } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import { CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import BreakpointSwitch from 'components/BreakpointSwitch'
import TelephoneNumber from 'components/TelephoneNumber'
import PATHS from 'lib/navigation/paths'
import GetAGuideLayout from 'views/cremation/components/GetAGuideLayout'

const pageTitle =
  'Funeral Services - Arrange a meaningful funeral with Farewill'
const pageDescription = `You can arrange a meaningful funeral today or we’ll help you plan ahead with a pre-paid funeral plan from Farewill. You can save ${formatPriceInPounds(
  CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE
)} compared to the average funeral in the UK.`

const StyledWrapper = styled(Wrapper)`
  ${screenMax.s`
    text-align: center;
  `}
`

type GetAGuideCallbackProps = {
  location: {
    state: {
      guideType: 'email' | 'post'
    }
  }
}

const GetAGuideCallback = ({
  location,
}: GetAGuideCallbackProps): ReactElement => {
  const { state } = location

  const guideType = state?.guideType ?? 'email'

  return (
    <GetAGuideLayout
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      guideType={guideType}
    >
      <StyledWrapper margin={[0, 0, 'XL']}>
        <Image
          path={
            guideType === 'email'
              ? 'e_trim/illustrations/laptop-with-cat'
              : 'e_trim/illustrations/letter-with-helper'
          }
          width={300}
        />
      </StyledWrapper>
      <H tag="h1" size="M">
        {guideType === 'email' ? `We’ve sent your guide` : `It’s in the post`}
      </H>
      <Wrapper maxWidth={540} margin={[0, 0, 'L']}>
        <P>
          {guideType === 'email'
            ? `We’ve emailed your guide, so you can read it anytime. We’ll give
                you a call to talk through it and answer any questions.`
            : `Your guide should be with you in the next 2 working days. We’ll give you a call to talk through it and answer any questions.`}
        </P>
        <P margin={[0, 0, 'L']}>
          If you’d like to speak sooner, give us a call now on{' '}
          <TelephoneNumber
            telephoneNumber="020 3966 3935"
            showIcon={false}
            strong={false}
            underline
          />
          .
        </P>
        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          belowComponent={
            <Button.Primary
              tag={Link}
              to={PATHS.CREMATION.FUNERAL_OVERVIEW}
              stretch
            >
              Back to Farewill Funerals
            </Button.Primary>
          }
          aboveComponent={
            <Button.Primary
              tag={Link}
              to={PATHS.CREMATION.FUNERAL_OVERVIEW}
              wide
            >
              Back to Farewill Funerals
            </Button.Primary>
          }
        />
      </Wrapper>
    </GetAGuideLayout>
  )
}

export default GetAGuideCallback
