import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { Grid, Wrapper } from '@farewill/ui'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'

import { PRODUCTS } from 'lib/products/constants'
import DefaultLayout from 'layouts/DefaultLayout'
import useWindowSize from 'lib/ui/useWindowSize'
import BreakpointSwitch from 'components/BreakpointSwitch'

import Brochure from '../Brochure'

const StyledGrid = styled(Grid)`
  position: relative;
  z-index: 1;
`

const StyledBackgroundGrid = styled(Grid)`
  position: fixed;
  inset: 0;
`

const StyledBackgroundGridItem = styled(Grid.Item)<{ $flex: boolean }>`
  ${({ $flex }) =>
    $flex &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const GetAGuideLayout = ({
  children,
  pageTitle,
  pageDescription,
  guideType,
}: PropsWithChildren<{
  pageTitle: string
  pageDescription: string
  guideType: 'email' | 'post'
}>): ReactElement => {
  const { width: windowWidth } = useWindowSize()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const BREAK_AT = BREAKPOINT.S

    if (windowWidth === null) {
      return setIsMobile(false)
    }

    return windowWidth < BREAK_AT ? setIsMobile(true) : setIsMobile(false)
  }, [windowWidth])

  return (
    <Wrapper background={COLOR.WHITE}>
      <DefaultLayout
        title={pageTitle}
        description={pageDescription}
        product={PRODUCTS.FUNERAL}
        headerColor={COLOR.WHITE}
        headerType="compact"
        headerHideFarewillLogo={!isMobile}
        headerHideTelephoneNumber={!isMobile}
        footerType={null}
        alignContent={!isMobile ? undefined : 'start'}
      >
        <StyledGrid container gapFromL="XXL">
          <Grid.Item spanFromM={6}>{children}</Grid.Item>
        </StyledGrid>
        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          aboveComponent={
            <StyledBackgroundGrid>
              <StyledBackgroundGridItem
                spanFromS={6}
                background={COLOR.WHITE}
              />
              <StyledBackgroundGridItem
                spanFromS={6}
                background={COLOR.ACCENT.PRIMARY_20}
                $flex
                centered
              >
                <Brochure guideType={guideType} />
              </StyledBackgroundGridItem>
            </StyledBackgroundGrid>
          }
        />
      </DefaultLayout>
    </Wrapper>
  )
}

export default GetAGuideLayout
